import Login from "@/components/Login";
import TitleComponent from "@/components/Title";
import React from "react";

const LoginPage: React.FC = () => {
  return (
    <>
      <TitleComponent title="JOBoostへようこそ" />
      <Login />
    </>
  );
};

export default LoginPage;

import { useDataContext } from "@/contexts/dataProvider";
import { detailTenant } from "@/services/api";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Snackbar,
  TextField
} from "@mui/material";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { detailUser } from "../services/api";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState(""); // For new password
  const [user, setUser] = useState<CognitoUser | null>(null); // To keep track of the Cognito user object
  const [error, setError] = useState<string | null>(null);
  const [authError, setAuthError] = useState<string | null>(null);
  const [passwordValidationErrors, setPasswordValidationErrors] = useState<
    string[]
  >([]);
  const [showPassword, setShowPassword] = useState(false);
  const router = useRouter();
  const { tenantData, updateTenantData, userData, updateUserData } =
    useDataContext();

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const user: CognitoUser | any = await Auth.signIn(email, password); // Type as any to avoid TypeScript errors
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setUser(user); // Save the user object to show the new password form
      } else {
        console.log(user);
        const tenant = await detailTenant({
          tenant_id: user.attributes["custom:tenant_id"],
        });
        const loginUser = await detailUser({
          tenant_id: user.attributes["custom:tenant_id"],
          email: user.attributes.email,
        });
        updateTenantData(tenant);
        updateUserData(loginUser);
        router.push("/upload"); // No challenge, proceed to upload
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setAuthError(error.message);
      }
    }
  };

  const validatePassword = (password: string) => {
    const errors: string[] = [];
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
      password
    );
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);

    if (password.length < 8) {
      errors.push("パスワードは8文字以上である必要があります。");
    }
    if (!hasNumber) {
      errors.push("少なくとも 1 つの数字を含む必要があります。");
    }
    if (!hasSpecialChar) {
      errors.push("少なくとも 1 つの特殊文字を含む必要があります。");
    }
    if (!hasUppercase) {
      errors.push("少なくとも 1 つの大文字を含む必要があります。");
    }
    if (!hasLowercase) {
      errors.push("少なくとも 1 つの小文字を含む必要があります。");
    }

    setError(errors.join("\n"));
    return errors;
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
    const errors = validatePassword(e.target.value);
    setPasswordValidationErrors(errors);
  };

  const handleNewPassword = async () => {
    if (user && passwordValidationErrors.length === 0) {
      try {
        await Auth.completeNewPassword(user, newPassword);
        router.push("/upload");
      } catch (error: unknown) {
        if (error instanceof Error) {
          setAuthError(error.message);
        }
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {!user && (
          <Box
            component="form"
            noValidate
            sx={{ mt: 1 }}
            onSubmit={handleLogin}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showPassword}
                  onChange={(e) => setShowPassword(e.target.checked)}
                />
              }
              label="パスワードを表示"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
            <Box textAlign="right">
              <Link href="/forgot-password">
                パスワードを忘れてしまった方はこちら
              </Link>
            </Box>
          </Box>
        )}
        {user && (
          <Box
            component="form"
            noValidate
            sx={{ mt: 1 }}
            onSubmit={handleNewPassword}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={handlePasswordChange} // onChangeイベントでバリデーションを行う
            />
            {/* パスワードのバリデーションエラーメッセージをリスト形式で表示 */}
            {passwordValidationErrors.map((error, index) => (
              <div key={index} style={{ color: "red" }}>
                {error}
              </div>
            ))}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={passwordValidationErrors.length > 0} // エラーが存在する場合はボタンを無効化
            >
              新しいパスワードを設定
            </Button>
          </Box>
        )}

        {/* Error Snackbar */}
        {authError && (
          <Snackbar
            open={Boolean(authError)}
            autoHideDuration={6000}
            onClose={() => setAuthError(null)}
          >
            <Alert onClose={() => setAuthError(null)} severity="error">
              {authError}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Container>
  );
};

export default Login;

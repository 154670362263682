import React from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { Container, Box, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

interface TitleComponentProps {
  title: string;
}

const TitleComponent: React.FC<TitleComponentProps> = ({ title }) => {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMdScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLgScreen = useMediaQuery(theme.breakpoints.up("lg"));

  let typographyVariant: TypographyProps["variant"] = "h4";
  if (isXsScreen) {
    typographyVariant = "h5";
  } else if (isSmScreen) {
    typographyVariant = "h4";
  } else if (isMdScreen) {
    typographyVariant = "h3";
  } else if (isLgScreen) {
    typographyVariant = "h2";
  }

  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          variant={typographyVariant}
          gutterBottom
          sx={{
            width: "100%",
            whiteSpace: "normal",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>
      </Box>
    </Container>
  );
};

export default TitleComponent;
